<template>
  <div v-bind:style="{ minHeight: minHeight + 'px' }">

    <!-- #page-header -->
    <div id="page-header" class="cover -small" data-image-src="assets/img/bg/kontakt.jpg">
      <div class="container">

        <div class="-wrapper">
          <div class="_inner">

            <h1 v-translate>Ďakujeme za vašu správu</h1>

          </div>
        </div>

      </div>
    </div>
    <!-- /#page-header -->

    <!-- .section -->
    <section class="section">
      <div class="container">


            <h2 class="text-center" v-translate>Čoskoro sa vám ozveme.</h2>

      </div>
    </section>
    <!-- /.section -->


  </div>
</template>

<script>
export default {
  name: 'registration',
    metaInfo () {
        return {
            title: this.$gettext('message_sent_title'),
            titleTemplate: '%s | Webpartner',
            htmlAttrs: {
                lang: 'sk-SK',
                amp: undefined
            },
            meta: [
                {name: 'ROBOTS', content: 'NOINDEX, NOFOLLOW'}
            ],
            link: [
                {rel: 'canonical', href: 'https://webpartner.sk/sprava-odoslana'}
            ]
        }
    },
    data() {
        return {
            window: {
                height: 0
            },
            footerHeight: 0,
            minHeight: 0
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize)
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    },
    mounted() {
        this.handleResize();
        this.footerHeight = this.$parent.$refs.mainFooter.$refs.footerElement.clientHeight;
        if(this.window.height > 200) {
            this.minHeight = this.window.height - this.footerHeight;
        }
    },
    methods: {
        handleResize() {
            this.window.height = window.innerHeight;
        }
    }

}
</script>
